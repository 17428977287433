import { useEffect, useState } from 'react'
import { Button, Drawer, Space } from 'antd'
import CreateNewTask from 'components/Task/TaskCreation'
import TaskTable from 'components/Task/TaskList/TaskTable'
import { useGetTaskSListQuery } from './tasksApiSlice'

const Tasks = () => {

  const {
    data: tasksData,
    isLoading: isTasksDataLoading,
    isSuccess: isTasksDataSuccess,
    isError: isTasksDataError,
    refetch: taskListRefetch,
    error: taskDistrictDataError
  } = useGetTaskSListQuery(undefined, {refetchOnMountOrArgChange:true})

  const [drawerState, setDrawerState] = useState(false)
  const [tasksList, setTasksList] = useState([])

  useEffect(() => {
    if (tasksData && tasksData.data && tasksData.data.length > 0) {
      setTasksList(tasksData && tasksData.data)
    }
  }, [tasksData])

  const handleTaskCreationDrawer = (isOpen) => {
    setDrawerState(isOpen)
  }

  return (
    <div className='border w-full bg-white border-gray-300 shadow p-5'>
      <Drawer
        title="Create a task"
        width={720}
        onClose={() => handleTaskCreationDrawer(false)}
        open={drawerState}
      >
        <CreateNewTask handleTaskCreationDrawer={handleTaskCreationDrawer} />
      </Drawer>
      <Space className='mb-6 flex justify-between'>
        <Space>
          <Button className='!rounded-sm' onClick={() => console.log("clear logic")}>Clear filters and sorters</Button>
        </Space>
        <Space>
          <Button
            // className='rounded-none border-gray-300 shadow'
            className='bg-french-lilac-500 border-none outline-none text-white hover:!text-white text-center !rounded-sm'
            onClick={() => handleTaskCreationDrawer(!drawerState)}>Create Task</Button>
        </Space>
      </Space>
      {/* <TaskTable tasks={tasksList} /> */}
    </div>
  )
}

export default Tasks