import { Button, Cascader, Checkbox, Col, Divider, Form, Radio, Row, Tabs } from 'antd'
import Map from 'components/map'
import React, { useEffect, useState } from 'react'
import { useGetTaskByIdQuery } from './tasksApiSlice'
import { useParams } from 'react-router-dom'
import { Backdrop, Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, Paper, TextField } from '@mui/material'
import ReactApexChart from 'react-apexcharts'

const TaskById = () => {
  const { id } = useParams();
  const taskType = window.location.search.replace('?type=', '')

  const {
    data: tasksData,
    isFetching: isTasksDataLoading,
    isSuccess: isTasksDataSuccess,
    isError: isTasksDataError,
    refetch: taskListRefetch,
    error: taskDistrictDataError
  } = useGetTaskByIdQuery(id, { refetchOnMountOrArgChange: true })

  // const [taskDetails, setTaskDetails] = useState({ state: "", district: "", crop_name: "" })
  // useEffect(() => {
  //   setTaskDetails({
  //     state: tasksData?.data?.task_details.state,
  //     district: tasksData?.data?.task_details.district,
  //     crop_name: tasksData?.data?.task_details.crop_name
  //   })
  // }, [tasksData])


  // const selectedIndices = Object.keys(tasksData?.data?.task_details?.spectral_details || {}).map((parameter) => {
  //   return {
  //     label: parameter,
  //     value: parameter,
  //     style: { marginBottom: "5px" },
  //     children: (tasksData?.data?.task_details?.spectral_details[parameter] || []).map((indice) => {
  //       return { label: indice, value: indice, style: { marginBottom: "5px" } }
  //     })
  //   }
  // })
  // const radioOptions = [
  //   { label: 'NDVI', value: 'NDVI', style: { marginBottom: "5px" } },
  //   { label: 'NDMI', value: 'NDMI', style: { marginBottom: "5px" } },
  //   { label: 'NDRE', value: 'NDRE', style: { marginBottom: "5px" } },
  //   { label: 'CIG', value: 'CIG', style: { marginBottom: "5px" } },
  //   { label: 'CIRedEdge', value: 'CIRedEdge', style: { marginBottom: "5px" } }
  // ];
  const yearsArray = Array.from({ length: 2023 - 2015 + 1 }, (_, index) => ({ label: (2015 + index), value: (2015 + index) }));

  const monthsList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  // const [selectedMonths, setSelectedMonths] = useState([])
  // const onMonthChange = (e) => {
  //   if (!selectedMonths.includes(e.target.value)) {
  //     setSelectedMonths([...selectedMonths, e.target.value])
  //   }
  // }

  // const [selectedYears, setSelectedYears] = useState([])
  // const onYearChange = (e) => {
  //   if (!selectedYears.includes(e.target.value)) {
  //     setSelectedYears([...selectedYears, e.target.value])
  //   }
  // }

  const IndicesKeyValueMapper = {
    RVI: 'RVI',
    NDVI: 'NDVI',
    NDMI: 'NDMI',
    NDRE: 'NDRE',
    CIG: 'CIG',
    CIRedEdge: 'CIRedEdge',
    Temperature_Air_2m_Min_24h: 'Min Temperature',
    Temperature_Air_2m_Max_24h: 'Max Temperature',
    Precipitation_Flux: 'Precipitation',
    Wind_Speed_10m_Mean: 'Wind Speed',
    Relative_Humidity_2m_06h: 'Relative Humidity',
    Solar_Radiation_Flux: 'Solar Radiation'
  }

  const [indices, setIndices] = useState([])
  const [years, setYears] = useState([])

  useEffect(() => {
    const indicesData = Object.keys(tasksData?.data?.task_details?.spectral_details || {}).map((key) => {
      if (tasksData?.data?.task_details?.spectral_details[key]?.length === 0) { return null }
      return {
        value: key,
        label: key,
        isLeaf: false,
        children: tasksData?.data?.task_details?.spectral_details[key]?.map((indice) => {
          return { value: indice, label: IndicesKeyValueMapper[indice], isLeaf: true }
        })
      }
    })

    const yearsData = Object.keys(tasksData?.data?.task_details?.temporal_details || {}).map((key) => {
      return {
        value: key,
        label: key,
        isLeaf: true,
      }
    })
    setIndices(indicesData.filter((indice) => indice !== null))
    setYears(yearsData)
  }, [isTasksDataLoading, JSON.stringify(tasksData)])


  const [selectedIndice, setSelectedIndice] = useState('')
  const [selectedYear, setSelectedYear] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('')
  const [selectedAggrigation, setSelectedAggrigation] = useState('MEAN')

  const [openModal, setOpenModal] = useState(false)

  // const [timeseries, setTimeSeries] = useState([])
  const [cogFileName, setCogFileName] = useState('')

  const [selectedFilters, setSelectedFilters] = useState({
    selectedIndice: '',
    selectedYear: '',
    selectedMonth: '',
    selectedAggrigation: '',
    timeseries: [],
    cogFileName: ''
  })

  useEffect(() => {
    if ((selectedIndice?.length !== 2)) {
      setSelectedFilters({
        selectedIndice: '',
        selectedYear: '',
        selectedMonth: '',
        selectedAggrigation: '',
        timeseries: [],
        cogFileName: ''
      })
      return
    }
    setSelectedFilters((prev) =>{
      return {...prev, selectedIndice: selectedIndice, timeseries: tasksData?.data?.data[selectedIndice[0]][selectedIndice[1]]['TIMESERIES']}
    })
  }, [selectedIndice])

  const onSubmit = () => {
    if ((selectedIndice?.length !== 2) || !selectedYear || !selectedMonth || !selectedAggrigation) {
      setOpenModal(true)
      return
    }

    let cogName = selectedIndice[1] + '_' + selectedAggrigation + '_' + selectedYear + '_' + selectedMonth
    setCogFileName(tasksData?.data?.data[selectedIndice[0]][selectedIndice[1]][selectedAggrigation][cogName])
    // setTimeSeries(tasksData?.data?.data[selectedIndice[0]][selectedIndice[1]]['TIMESERIES'])

    setSelectedFilters({
      selectedIndice: selectedIndice,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      selectedAggrigation: selectedAggrigation,
      timeseries: tasksData?.data?.data[selectedIndice[0]][selectedIndice[1]]['TIMESERIES'],
      cogFileName: tasksData?.data?.data[selectedIndice[0]][selectedIndice[1]][selectedAggrigation][cogName]
    })
  }

  const options = {
    grid: {
      show: true,
      borderColor: '#cadbdb',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        },

      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: undefined,
        opacity: 0.1
      },
      column: {
        colors: undefined,
        opacity: 0.1
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },
    chart: {
      height: 500,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    xaxis: {
      type: 'line',
      categories: selectedFilters.timeseries.map((item) => item.date),
      tickAmount: 24,
    },
    
    tooltip: {
      x: {
        format: 'YYYY-mm-dd'
      },
      
    },
  }

  return (
    <div className='w-full h-screen overflow-auto'>

      <Backdrop
        sx={{ color: '#fff', zIndex: 440 }}
        open={isTasksDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {
        taskType === "Acreage" ? (
          <div className='w-full h-full'>

            <div className='w-[100%] h-full gap-6 flex'>
              <Map CogUrl={tasksData?.data?.acreage_url} />
              <div className='w-[30%] border h-full bg-white border-gray-300 shadow p-5'>

                <Divider>Details</Divider>

                <Box className="flex flex-col gap-4">
                  <TextField sx={{ width: "100%" }} className=' pointer-events-none' value={tasksData?.data?.task_details?.state || "."} id="state" label="State" variant="filled" />
                  <TextField sx={{ width: "100%" }} className=' pointer-events-none' value={tasksData?.data?.task_details?.district || "."} id="district" label="District" variant="filled" />
                  <TextField sx={{ width: "100%" }} className=' pointer-events-none' value={tasksData?.data?.task_details?.crop_name || "."} id="crop_name" label="Crop" variant="filled" />
                </Box>
                <Button href={tasksData?.data?.acreage_url} className='w-full mt-6'>Download</Button>
              </div>
            </div>
          </div>
        ) : null
      }
      {
        taskType === "Spectral%20Profiling" ? (
          <div className='w-full h-full'>

            <div className='w-[100%] h-full gap-6 flex'>
              <Map geoJson={Object.keys(tasksData?.data?.task_details?.geoJson || {}).length > 0 ? tasksData?.data?.task_details?.geoJson : null} />
              <div className='w-[30%] border h-full bg-white border-gray-300 shadow p-5'>

                <Divider>Details</Divider>

                <Box className="flex flex-col gap-4">
                  <TextField sx={{ width: "100%" }} className=' pointer-events-none' value={tasksData?.data?.task_details?.state || "."} id="state" label="State" variant="filled" />
                  <TextField sx={{ width: "100%" }} className=' pointer-events-none' value={tasksData?.data?.task_details?.district || "."} id="district" label="District" variant="filled" />
                  <TextField sx={{ width: "100%" }} className=' pointer-events-none' value={tasksData?.data?.task_details?.crop_name || "."} id="crop_name" label="Crop" variant="filled" />
                </Box>
              </div>
            </div>
          </div>
        ) : null
      }
      {

        taskType === "ARD" ? (
          <div className='w-full h-full overflow-auto'>
            <Dialog
              open={openModal}
              onClose={() => { setOpenModal(false) }}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"InComplete Filters"}</DialogTitle>
              <DialogContent dividers>
                <DialogContentText id="alert-dialog-slide-description">
                  There are some missing or incomplete filters. please select all the filters and try again.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant='outlined' onClick={() => setOpenModal(false)}>Cancel</Button>
                <Button className='!text-white !bg-orange-500' variant='contained' onClick={() => setOpenModal(false)}>Ok</Button>
              </DialogActions>
            </Dialog>
            <div className='w-[100%] h-[60%] gap-6 flex'>
              <div className='w-full h-full flex flex-col gap-2'>
                <div className='w-full h-full'>
                  <Map
                    CogUrl={cogFileName ? cogFileName : null}
                    geoJson={Object.keys(tasksData?.data?.task_details?.geometry || {}).length > 0 ? tasksData?.data?.task_details?.geometry : null} />
                </div>
              </div>
              <div className='w-[32%] border h-full bg-white border-gray-300 shadow p-4'>

                <Divider className='!my-2'>Filters</Divider>
                <Form layout="vertical">
                  <Form.Item
                    name="indices"
                    label="Parameter"
                  >
                    <Cascader
                      expandTrigger="hover"
                      onChange={(event) => setSelectedIndice(event)}
                      className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
                      dropdownMatchSelectWidth={true}
                      placeholder={"Select Parameter"}
                      options={indices}
                      changeOnSelect
                    />
                  </Form.Item>

                  <div className='flex justify-between gap-2'>
                    <Form.Item
                      name="year"
                      label="Year"
                      className='w-full'
                    >
                      <Cascader
                        onChange={(event) => setSelectedYear(event && event[event.length - 1])}
                        className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
                        dropdownMatchSelectWidth={true}
                        placeholder={"Select Year"}
                        options={years}
                        changeOnSelect
                      />
                    </Form.Item>
                    <Form.Item
                      name="month"
                      label="Month"
                      className='w-full'
                    >
                      <Cascader
                        onChange={(event) => setSelectedMonth(event && event[event.length - 1])}
                        className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
                        dropdownMatchSelectWidth={true}
                        placeholder={"Select Month"}
                        options={selectedYear && tasksData?.data?.task_details?.temporal_details[selectedYear]?.map((month) => {
                          return { value: month, label: month, isLeaf: true }
                        })}
                        changeOnSelect
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="aggregations"
                    label="Aggregations"
                    initialValue={selectedAggrigation}
                  >
                    <Radio.Group onChange={(event) => setSelectedAggrigation(event.target.value)} className='' defaultChecked>
                      {['MIN', 'MAX', 'MEAN'].map((option, index) => {
                        return <Radio defaultChecked key={index} value={option}>{option}</Radio>
                      })}
                    </Radio.Group>
                  </Form.Item>
                  <Divider />

                  <Form.Item className='flex justify-center'>
                    <Button className='!rounded-none bg-orange-500 hover:!text-black' onClick={() => onSubmit()}>Apply Filters</Button>
                  </Form.Item>

                </Form>
              </div>
            </div>
            <div className={`w-full h-[70%] mt-6 ${selectedIndice?.length !== 2 ? "hidden": ""}`}>
              <Paper className='w-full h-full flex justify-center border p-4'>
                <Box className="!w-full h-full space-y-4">
                  <Box>{selectedFilters.selectedIndice.length === 2 ? IndicesKeyValueMapper[selectedFilters.selectedIndice[1]] : null}</Box>
                  <ReactApexChart options={options} series={[
                    {
                      name: 'Min',
                      data: selectedFilters.timeseries?.map((item) => item[selectedFilters.selectedIndice[selectedFilters.selectedIndice.length - 1] + '_MIN']?.toFixed(2))
                    },
                    {
                      name: 'Mean',
                      data: selectedFilters.timeseries?.map((item) => item[selectedFilters.selectedIndice[selectedFilters.selectedIndice.length - 1] + '_MEAN']?.toFixed(2))
                    },
                    {
                      name: 'Max',
                      data: selectedFilters.timeseries?.map((item) => item[selectedFilters.selectedIndice[selectedFilters.selectedIndice.length - 1] + '_MAX']?.toFixed(2))
                    }
                  ]} type="line" height={400} />
                </Box>
              </Paper>
            </div>
          </div>
        ) : null
      }

    </div>
  )
}

export default TaskById