import Layout from "Layout";
import RequireAuth from "app/auth/RequireAuth";
import NotFound from "components/global/NotFound";
import Dashboard from "pages/Dashboard";
import DataPipeline from "pages/DataPipeline";
import Tasks from "pages/Tasks";
import TaskById from "pages/Tasks/TaskById";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Suspense fallback={<div>Loading ...</div>}>
      <Routes>
        {/* <Route element={<RequireAuth />}> */}
        {/* <Route element={<Layout />}> */}
          <Route path="/">
            <Route index element={<Dashboard />} />
            <Route path="tasks" >
              <Route index element={<Tasks></Tasks>} />
              <Route path=":id" element={<TaskById></TaskById>} />
            </Route>
          </Route>
        {/* </Route> */}
        {/* <Route path="/*" element={<NotFound />} /> */}
        {/* </Route> */}
      </Routes>
    </Suspense>
  );
}

export default App;
