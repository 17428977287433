import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./api/apiSlice"
import authReducer from 'app/auth/authSlice'
import { loadState, saveState } from "data/localstorage"
import menuReducer from './menuSlice';

const persistedState = loadState()

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    menu: menuReducer
  },
  preloadedState: persistedState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production'
})

store.subscribe(() => {
  saveState(store.getState())
})