import { Form, Cascader, Input } from 'antd'
import React, { useState } from 'react'


const TaskTemporalDetailsStep = ({temporalDetailsData, setTemporalDetailsData}) => {
  const [options, setOptions] = useState([]);

  const generateMonths = (year) => {
    const months = [];
    const MonthNames =  ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    for (let i = 1; i <= 12; i++) {
      months.push({ value: `${i}`, label: `${year +'_' + MonthNames[i-1]}`, isLeaf: true });
    }
    return months;
  };

  const generateDays = (month) => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push({ value: `${i}`, label: `${month +'_'+i}` });
    }
    return days;
  };

  const generateYears = () => {
    const years = [];
    for (let year = 2015; year <= 2023; year++) {
      years.push({
        value: `${year}`,
        label: `${year}`,
        isLeaf: false,
        children: generateMonths(year).map(month => ({
          ...month,
          // children: generateDays(month.label),
        })),
      });
    }
    return years;
  };

  React.useEffect(() => {
    setOptions(generateYears());
  }, []);


  return (
    <Form layout="vertical" className='p-5 md:p-7 lg:p-10 ' requiredMark>
      <Form.Item
        name="dates"
        label="Dates"
        rules={[
          {
            required: true,
            message: 'Please select Dates',
          },
        ]}
      >
        <Cascader
          multiple
          onChange={(event)=>setTemporalDetailsData({...temporalDetailsData, years:event})}
          className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
          dropdownMatchSelectWidth={true}
          options={options}
          changeOnSelect
        />
      </Form.Item>
      
      
    </Form>
  )
}

export default TaskTemporalDetailsStep