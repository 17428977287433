import { createSlice } from '@reduxjs/toolkit';
import { loadState } from 'data/localstorage';

const initialState = {
  activeItem: null,
  defaultactiveItemId: 'tasks',
  openComponent: 'buttons',
  drawerOpen: true,
  componentDrawerOpen: true
};

const prevState = loadState()

const menu = createSlice({
  name: 'menu',
  initialState: {...initialState, ...prevState.menu},
  reducers: {
    setActiveItem(state, action) {
      state.activeItem = action.payload.activeItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    }
  }
});

export default menu.reducer;

export const { setActiveItem, activeComponent, openDrawer, openComponentDrawer } = menu.actions;
