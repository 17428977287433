import { Close, Description } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'

const FileUpload = ({setFiles=()=>{}}) => {

  const [selectedfiles, SetSelectedFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const handleDeleteFile = (id) => {
    SetSelectedFiles((prev) => prev.filter((file) => file.id !== id))
  }

  const onFileChange = (event) => {
    const keys = Object.keys(event.target.files || {})
    setFilesToUpload(event.target.files)

    keys.map((key, index) => {
      const file = event.target.files[key]

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        SetSelectedFiles((prev) => [
          ...prev,
          {
            id: index,
            filename: file.name,
            filetype: file.type,
            fileimage: reader.result,
            datetime: file.lastModifiedDate.toLocaleString('en-IN'),
            filesize: filesizes(file.size)
          }
        ])
      }
    })
  }

  useEffect(() => {
    console.log("selected files", selectedfiles)
    setFiles(filesToUpload)
  }, [selectedfiles])

  return (
    <Box className="w-full flex flex-col gap-4">
      <Box className="border border-dashed relative border-gray-400 bg-gray-50 rounded p-12 ">
        <Box className="text-center space-y-1">
          <input type="file" className="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer" multiple onChange={onFileChange} />
          <p className='text-gray-600 tracking-wide'><strong className='underline'>Browse for files</strong> or drag and drop here to upload.</p>
          <p className='text-gray-500 text-xs tracking-wide'>Only GeoJson format allowed</p>
        </Box>
      </Box>

      {
        selectedfiles.length > 0 ? (
          <Box className="w-full flex justify-center items-center">
            <Box className="w-full flex flex-col space-y-1 p-2">
              {
                selectedfiles.map((data, index) => {
                  const { id, filename, filetype, fileimage, datetime, filesize } = data;
                  return (
                    <Box className="flex justify-between items-center border rounded p-1" key={index}>

                      <Box className="flex items-center space-x-6">
                        <Box className="w-16 h-12  flex justify-center items-center bg-cover rounded bg-[#eaecf1] p-2 text-[#475f7b]">
                          {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? <img src={fileimage} alt="" /> : <Description className='!text-[35px]' />}
                        </Box>
                        <Box>
                          <p className='text-[13px] font-semibold'>{filename}</p>
                          <p className='text-[12px] text-[#8194aa]'><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                        </Box>
                      </Box>
                      <IconButton onClick={() => handleDeleteFile(id)} className='!bg-gray-100 !rounded !text-red-600'>
                        <Close fontSize='small' />
                      </IconButton>

                    </Box>
                  )
                })
              }
            </Box>
          </Box>
        ) : null
      }
    </Box>
  )
}

export default FileUpload