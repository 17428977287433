import React, { useEffect, useState } from 'react'
import Stepper from 'components/Form/Stepper'
import TaskSpectralDetailsStep from './TaskSpectralDetailsStep'
import TaskTemporalDetailsStep from './TaskTemporalDetailsStep'
import TaskLocationDetailsStep from './TaskLocationDetailsStep'
import { useCreateTaskMutation } from 'pages/Tasks/tasksApiSlice'
import TaskCreationMessage from './TaskCreationMessage'

const TaskCreationSteps = ({ handleTaskCreationDrawer }) => {
  const [createTask, createTaskresponse] = useCreateTaskMutation()

  const [TaskPayload, setTaskPayload] = useState({})
  const [locationDetailsData, setLocationDetailsData] = useState({
    taskName: "",
    locationType: "file",
    file: null,
    stateName: "",
    stateCode: "",
    districtName: "",
    districtCode: ""
  })

  const [taskCreatedStatus, setTaskCreatedStatus] = useState(null)
  const handleTaskCreationOnSubmit = () => {
    createTask(TaskPayload).unwrap().then((response) => {
      try {
        setTaskCreatedStatus(response.status)
      } catch {
        setTaskCreatedStatus(null)
      }
    }).catch((error) => console.log(error))
  }

  const [current, setCurrent] = useState(0);

  const [spectralDetailsData, setSpectralDetailsData] = useState({
    weather: {
      indices: []
    },
    MSI: {
      indices: []
    },
    SAR: {
      indices: []
    }
  })
  const [temporalDetailsData, setTemporalDetailsData] = useState({
    years: []
  })

  const [nextStepEnabled, setNextStepEnabled] = useState(false)

  const CreateOneMoreTask = () => {
    setCurrent(0)
    setTaskCreatedStatus(null)
    setTaskPayload({})
    setLocationDetailsData({
      taskName: "",
      season: "",
      locationType: "file",
      file: null,
      stateName: "",
      stateCode: "",
      districtName: "",
      districtCode: ""
    })

    setSpectralDetailsData({
      weather: {
        indices: []
      },
      MSI: {
        indices: []
      },
      SAR: {
        indices: []
      }
    })
    setTemporalDetailsData({
      years: []
    })
    setNextStepEnabled(false)
  }

  const GoHome = () => {
    handleTaskCreationDrawer(false)
    CreateOneMoreTask()
  }


  useEffect(() => {
    if (current === 0) {
      if (!locationDetailsData.taskName) {
        setNextStepEnabled(false)
        return
      }
      if (locationDetailsData.locationType === 'file' && !locationDetailsData.file) {
        setNextStepEnabled(false)
        return
      }
      if (locationDetailsData.locationType === 'district' && !locationDetailsData.districtCode) {
        setNextStepEnabled(false)
        return
      }

      const TempTaskPayload = {
        taskName: locationDetailsData.taskName,
        locationType: locationDetailsData.locationType,
        season: "",
        gjson: {},
      }
      if (locationDetailsData.locationType === 'file') {
        TempTaskPayload.file = locationDetailsData.file
      } else {
        TempTaskPayload.stateCode = locationDetailsData.stateCode
        TempTaskPayload.stateName = locationDetailsData.stateName
        TempTaskPayload.district_id = locationDetailsData.districtCode
        TempTaskPayload.districtName = locationDetailsData.districtName
      }
      setTaskPayload(TempTaskPayload)
      setNextStepEnabled(true)
    }
    else if (current === 1) {
      setNextStepEnabled(false)
      if (spectralDetailsData.weather.indices.length === 0 && spectralDetailsData.MSI.indices.length === 0 && spectralDetailsData.SAR.indices.length === 0) return

      if (spectralDetailsData.weather.indices.length > 0) {
        if (spectralDetailsData.weather.indices.length >= 1) {
          if(typeof spectralDetailsData.weather.indices[0] === 'string'){
            spectralDetailsData.weather.indices = spectralDetailsData.weather.indices
          }
          else{
            spectralDetailsData.weather.indices = spectralDetailsData.weather.indices.map((ind) => ind[0])
          }
        }
        spectralDetailsData.weather.indices = spectralDetailsData.weather.indices.map((ind) => ind)
      } else {
        spectralDetailsData.weather.indices = []
      }

      if (spectralDetailsData.MSI.indices.length > 0) {
        if (spectralDetailsData.MSI.indices.length >= 1) {
          if(typeof spectralDetailsData.MSI.indices[0] === 'string'){
            spectralDetailsData.MSI.indices = spectralDetailsData.MSI.indices
          }else{
            spectralDetailsData.MSI.indices = spectralDetailsData.MSI.indices.map((ind) => ind[0])
          }
        }
        spectralDetailsData.MSI.indices = spectralDetailsData.MSI.indices.map((ind) => ind)
      } else {
        spectralDetailsData.MSI.indices = []
      }

      if (spectralDetailsData.SAR.indices.length > 0) {
        if (spectralDetailsData.SAR.indices.length >= 1) {
          if(typeof spectralDetailsData.SAR.indices[0] === 'string'){
            spectralDetailsData.SAR.indices = spectralDetailsData.SAR.indices
          }else{
            spectralDetailsData.SAR.indices = spectralDetailsData.SAR.indices.map((ind) => ind[0])
          }
        }
        spectralDetailsData.SAR.indices = spectralDetailsData.SAR.indices.map((ind) => ind)
      } else {
        spectralDetailsData.SAR.indices = []
      }

      setTaskPayload({ ...TaskPayload, ...spectralDetailsData })
      setNextStepEnabled(true)
    }
    else if (current === 2) {
      setNextStepEnabled(false)
      if (temporalDetailsData.years.length === 0) return

      setTaskPayload({ ...TaskPayload, ...temporalDetailsData })
      setNextStepEnabled(true)
    }

  }, [locationDetailsData, spectralDetailsData, temporalDetailsData, current])

  useEffect(() => {
    CreateOneMoreTask()
  }, [])

  useEffect(() => {
    console.log(TaskPayload)
  }, [TaskPayload])

  const steps = [
    {
      title: "Location Details",
      content: <TaskLocationDetailsStep locationDetailsData={locationDetailsData} setLocationDetailsData={setLocationDetailsData} setNextStepEnabled={setNextStepEnabled} />
    },
    {
      title: "Spectral Details",
      content: <TaskSpectralDetailsStep spectralDetailsData={spectralDetailsData} setSpectralDetailsData={setSpectralDetailsData} setNextStepEnabled={setNextStepEnabled} />
    },
    {
      title: "Temporal Details",
      content: <TaskTemporalDetailsStep temporalDetailsData={temporalDetailsData} setTemporalDetailsData={setTemporalDetailsData} setNextStepEnabled={setNextStepEnabled} />
    }
  ]
  if (taskCreatedStatus) {
    return (
      <TaskCreationMessage taskCreatedStatus={taskCreatedStatus} CreateOneMoreTask={CreateOneMoreTask} handleTaskCreationDrawer={GoHome} />
    )
  }

  return (
    <Stepper steps={steps} current={current} setCurrent={setCurrent} nextStepEnabled={nextStepEnabled} handleTaskCreationOnSubmit={handleTaskCreationOnSubmit} />
  )
}

export default TaskCreationSteps
