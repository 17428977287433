import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'app/store';
import { OIDC_AUTH_SERVER_URL, OIDC_CLIENT_ID } from 'app/config';
import 'leaflet/dist/leaflet.css';
import 'simplebar/dist/simplebar.css'
import 'index.css'
import { ConfigProvider } from 'antd';


const root = ReactDOM.createRoot(document.getElementById('root'));

const oidcConfig = {
  authority: OIDC_AUTH_SERVER_URL,
  client_id: OIDC_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.host}/`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`
};

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{

        token: {

          borderRadius: "2px"


        },

      }}
    >
      <Provider store={store}>
        <AuthProvider {...oidcConfig}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
