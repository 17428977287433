import React, { useState } from 'react';
import { Button, message, Steps, theme } from 'antd';

const Stepper = ({ steps = [], current, setCurrent, nextStepEnabled, handleTaskCreationOnSubmit }) => {
  const { token } = theme.useToken();
  
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };
  return (
    <>
      <Steps current={current} items={items} />
      <div className='border' style={contentStyle} >{steps[current].content}</div>
      <div
        style={{
          marginTop: 24,
          display: "flex",
          justifyContent: "right"
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button onClick={() => next()} disabled={!nextStepEnabled}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button onClick={() => handleTaskCreationOnSubmit()} disabled={!nextStepEnabled}>
            Done
          </Button>
        )}
      </div>
    </>
  );
};
export default Stepper;