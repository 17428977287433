import { Cascader, Divider, Form } from 'antd'
import React from 'react'

const TaskSpectralDetailsStep = ({ spectralDetailsData, setSpectralDetailsData }) => {
  return (
    <Form layout="vertical" className='p-5 md:p-7 lg:p-10 '>
      <Divider orientation="left" orientationMargin="0">
        Satellite Derived Indices
      </Divider>
      <Form.Item
        name="MSI"
        label="MSI"
        rules={[
          {
            required: true,
            message: 'Please select Indices',
          },
        ]}
      >
        <Cascader
          multiple
          onChange={(event) => setSpectralDetailsData({ ...spectralDetailsData, MSI: { indices: event } })}
          className='[&_.ant-select-selector]:!rounded-none [.ant-select-dropdown]:!border-none h-10 text-left'
          dropdownMatchSelectWidth={true}
          options={[{ label: 'NDVI', value: 'NDVI' }, { label: 'NDMI', value: 'NDMI' }, { label: 'NDRE', value: 'NDRE' }, { label: 'CIG', value: 'CIG' }, { label: 'CIRedEdge', value: 'CIRedEdge' }]}
          changeOnSelect
          showSearch
          onSearch={(value) => console.log(value)}
        />
      </Form.Item>
      <Form.Item
        name="SAR"
        label="SAR"
        rules={[
          {
            required: true,
            message: 'Please select Indices',
          },
        ]}
      >
        <Cascader
          multiple
          onChange={(event) => setSpectralDetailsData({ ...spectralDetailsData, SAR: { indices: event } })}
          className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
          dropdownMatchSelectWidth={true}
          options={[{ label: 'RVI', value: 'RVI' }]}
          changeOnSelect
          showSearch
          onSearch={(value) => console.log(value)}
        />
      </Form.Item>

      <Divider orientation="left" orientationMargin="0">
      Weather Indices
      </Divider>

      <Form.Item
        name="weather"
        label="Weather"
        rules={[
          {
            required: true,
            message: 'Please select Indices',
          },
        ]}
      >
        <Cascader
          multiple
          onChange={(event) => setSpectralDetailsData({ ...spectralDetailsData, weather: { indices: event } })}
          className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
          dropdownMatchSelectWidth={true}
          options={[{ label: 'Tmin', value: 'Temperature_Air_2m_Min_24h' }, { label: 'Tmax', value: 'Temperature_Air_2m_Max_24h' }, { label: 'Precipitation', value: 'Precipitation_Flux' }, { label: 'Wind Speed', value: 'Wind_Speed_10m_Mean'}, { label: 'Relative Humidity', value: 'Relative_Humidity_2m_06h' }, { label: 'Solar Radiation', value: 'Solar_Radiation_Flux' }]}
          changeOnSelect
          showSearch
          onSearch={(value) => console.log(value)}
        />
      </Form.Item>
      
    </Form>
  )
}

export default TaskSpectralDetailsStep