import React, { useEffect } from 'react'
import SimpleBar from 'simplebar-react'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import TopicRoundedIcon from '@mui/icons-material/TopicRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveItem } from 'app/menuSlice';

const menuItems = [
  {
    "id": "dashboard",
    "title": "Dashboard",
    "icon": DashboardRoundedIcon,
    "url": "/",
    "iconColorClass": "text-orange-500"
  },
  {
    "id": "tasks",
    "title": "Tasks",
    "icon": TaskOutlinedIcon,
    "url": "/tasks",
    "iconColorClass": "text-blue-500"
  },
  {
    "id": "reports",
    "title": "Reports",
    "url": "/reports",
    "icon": TopicRoundedIcon,
    "iconColorClass": "text-gray-500"
  }
]

const Sidebar = ({className}) => {

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { activeItem, defaultactiveItemId } = useSelector((state) => state.menu);
  const navigate = useNavigate()

  const navHandler = (item) => {
    dispatch(setActiveItem({ activeItem: { id:item.id, url:item.url } }));
    navigate(item.url)
  };

  useEffect(() => {
    if (!activeItem) {
      menuItems.forEach((item) => {
        if (item.id === defaultactiveItemId) {
          dispatch(setActiveItem({ activeItem: { id:item.id, url:item.url } }));
          navigate(item.url)
        }
      })
    }
  }, [activeItem, dispatch, navigate, defaultactiveItemId])

  useEffect(() => {
    menuItems.forEach((item) => {
      if (pathname.includes(item.url)) {
        if (item.id !== activeItem){
          dispatch(setActiveItem({ activeItem: { id:item.id, url:item.url } }));
        }
      }
    })
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <div className={className}>
      <header className=' flex justify-center items-center mb-8'>
        {/* <a href='/' className='bg-white p-0.5 border rounded-md w-[70%] flex justify-center items-center align-middle' style={{boxShadow: "2px 3px 11px 4px #ffffff70"}}>
          <img className='w-28 overflow-hidden -mt-2' src='https://comms-media.s3.amazonaws.com/prod_media/static/images/leadsNexTech-removebg-preview.png' alt='logo'></img>
        </a> */}
        <a href='/' className='flex justify-center items-center button w-[85%] h-14 bg-white p-0.5 rounded-lg cursor-pointer select-none
            active:translate-y-2  active:[box-shadow:0_0px_0_0_#3784ff,0_0px_0_0_#418aff33]
            active:border-b-[0px]
            transition-all duration-150 [box-shadow:0_4px_0_0_#3784ff,0_10px_0_0_#418aff33]
            border-b-[1px] border-blue-400'>
          {/* <img className='w-28 -mt-2 overflow-hidden' src='https://comms-media.s3.amazonaws.com/prod_media/static/images/leadsNexTech-removebg-preview.png' alt='logo'></img> */}
          <img className='w-28 -mt-2 overflow-hidden' src='https://mlt4wd2poh9x.i.optimole.com/VDx1EdM-gcY753JY/w:auto/h:auto/q:mauto/f:avif/http://www.leadsconnect.in/wp-content/uploads/2020/11/LC-logo.png' alt='logo'></img>
        </a>
      </header>

      <aside className=''>
        <div className="hover:cursor-pointer relative mx-3 mb-2">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <ManageSearchRoundedIcon fontSize='small' className='text-gray-500' />
          </span>
          <input type="text" className="w-full py-3 pl-10 pr-4 text-gray-300 bg-gray-800 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" placeholder="Search" />
        </div>

        <SimpleBar forceVisible="y" className='max-h-[79vh] p-2'>

          <div>
            {/* <span className='text-gray-500 text-sm font-medium' >Dashboard</span> */}

            <ul className='text-gray-500 flex flex-col gap-2 p-2 px-1'>

              {menuItems.map((item, index) => {
                return (
                  <li
                    key={index}
                    onClick={()=>navHandler(item)}
                    className={`
                    flex justify-between items-center py-3.5 px-4 rounded-md hover:cursor-pointer
                    ${item.id.toString() === activeItem?.id.toString() ? 'bg-gray-700 text-gray-300' : ''}`}>
                    <span className="text-sm font-medium flex">
                      <span className={`flex mr-2 ${item.iconColorClass}`}><item.icon fontSize='small' /></span>
                      {item.title}
                    </span>
                    {/* <span className='flex'><KeyboardArrowRightRoundedIcon fontSize='small' /></span> */}
                  </li>
                )
              })}

            </ul>

          </div>
        </SimpleBar>
      </aside>
    </div>
  )
}

export default Sidebar
