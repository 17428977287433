import { apiSlice } from "app/api/apiSlice"

export const MasterDataApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getStateMaster: builder.query({
      query: () => "/location_master?level=State"
    }),
    getDistrictMaster: builder.query({
      query: ({selectedState}) => `/location_master?level=District${selectedState ? `&state_id=${selectedState}` : ''}`
    }),
  })
})

export const {
  useGetStateMasterQuery,
  useGetDistrictMasterQuery
} = MasterDataApiSlice 