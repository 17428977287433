import React, { useEffect, useState } from 'react'
import { Cascader, Col, Form, Input, Row, Segmented, Select } from 'antd'
import DragUpload from 'components/Form/DragUpload'
import { useGetDistrictMasterQuery, useGetStateMasterQuery } from 'services/masterDataApiSlice'


const TaskLocationDetailsStep = ({locationDetailsData, setLocationDetailsData}) => {

  const locationTypeOptions = [
    {
      label: 'Upload File',
      value: 'file',
      className: '!rounded-none mr-2'
    },
    {
      label: 'Select District',
      value: 'geojson',
      className: '!rounded-none'
    }
  ]

  const [selectedState, setSelectedState] = useState(null)

  const {
    data: statesData,
    isLoading: isStatesDataLoading,
    isSuccess: isStatesDataSuccess,
    isError: isStatesDataError,
    error: statesDataError
  } = useGetStateMasterQuery()

  const {
    data: districtsData,
    isLoading: isDistrictsDataLoading,
    isSuccess: isDistrictsDataSuccess,
    isError: isDistrictsDataError,
    error: districtDistrictDataError
  } = useGetDistrictMasterQuery({ selectedState }, { skip: selectedState === null })

  const [statesList, setStatesList] = useState([])
  const [districtsList, setDistrictsList] = useState([])
  const [locationType, setLocationType] = useState(locationDetailsData.locationType)

  useEffect(() => {
    setStatesList(statesData && statesData.data.map((state) => {
      return {
        label: state.state_name,
        value: state.state_code
      }
    }))
  }, [statesData])

  useEffect(() => {
    setDistrictsList(isDistrictsDataSuccess && districtsData.data.map((district) => {
      return {
        label: district.district_name,
        value: district.district_code,
      }
    }))
  }, [districtsData, selectedState])

  const handleLocationTypeChange = (value) => {
    setLocationType(value)
    setLocationDetailsData({...locationDetailsData, locationType:value})
  }

  return (
    <Form layout="vertical" className='p-5 md:p-7 lg:p-10 ' requiredMark>
      <Row gutter={20} >
        <Col span={12}>
          <Form.Item
            name="taskName"
            label="Task Name"
            rules={[
              {
                required: true,
                message: 'Please enter a task name',
              },
            ]}
          >
            <Input value={locationDetailsData.taskName} name='taskName' className='rounded-none p-2' placeholder="Please enter a task name" onChange={(event)=>setLocationDetailsData((prevData) => ({...prevData, taskName: event.target.value,}))} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="locationType"
            label="Location Type"
          >
            <Segmented
              name='locationType'
              
              onChange={(e) => handleLocationTypeChange(e)}
              className='border border-gray-300 rounded-none p-[0.28rem] w-full overflow-auto'
              options={locationTypeOptions}
            />
          </Form.Item>
        </Col>
      </Row>

      {locationType === 'file' ? (
        <Col span={24}>
          <Form.Item
            name="file"
          >
            <DragUpload  />
          </Form.Item>
        </Col>
      ) : (
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="state"
              label="Select State"
              rules={[
                {
                  required: true,
                  message: 'Please select a State',
                },
              ]}
            >
              <Cascader
                className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
                dropdownMatchSelectWidth={true}
                options={statesList && statesList.sort((a, b) => (a.label > b.label) ? 1 : -1)}
                onChange={(e, label) => {
                  setSelectedState(e && e.toString() || null)
                  // setLocationDetailsData((prevData) => ({...prevData, stateCode: e && e[0],}))
                  setLocationDetailsData((prevData) => ({...prevData, stateCode: label && label[0].value,  stateName: label && label[0].label}))
                }}
                onClear={() => {
                  setSelectedState(null)
                  setDistrictsList([])
                }}
                changeOnSelect
                showSearch
                onSearch={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              label="Select District"
              rules={[
                {
                  required: true,
                  message: 'Please select a District',
                },
              ]}
            >
              <Cascader
                className='[&_.ant-select-selector]:!rounded-none [_.ant-select-dropdown]:!border-none h-10 text-left'
                dropdownMatchSelectWidth={true}
                options={districtsList && districtsList.sort((a, b) => (a.label > b.label) ? 1 : -1)}
                changeOnSelect
                onChange={(event, label)=>setLocationDetailsData((prevData) => ({...prevData, districtCode: label && label[0].value,  districtName: label && label[0].label}))}
                showSearch
                onSearch={(value) => console.log(value)}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  )
}

export default TaskLocationDetailsStep