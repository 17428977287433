import Button from '@mui/material/Button';
import { Box, OutlinedInput, TextField } from '@mui/material';
import Select from 'react-select';
import { useGetDistrictMasterQuery, useGetStateMasterQuery } from 'services/masterDataApiSlice';
import { useEffect, useState } from 'react';
import { InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import FileUpload from 'components/Form/FileUpload';
import { Cascader } from 'antd';


const ARDTask = () => {

  const [options, setOptions] = useState([]);

  const generateMonths = (year) => {
    const months = [];
    const MonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    for (let i = 1; i <= 12; i++) {
      months.push({ value: `${i}`, label: `${year + '_' + MonthNames[i - 1]}`, isLeaf: true });
    }
    return months;
  };

  const generateDays = (month) => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push({ value: `${i}`, label: `${month + '_' + i}` });
    }
    return days;
  };

  const generateYears = () => {
    const years = [];
    for (let year = 2015; year <= 2024; year++) {
      years.push({
        value: `${year}`,
        label: `${year}`,
        isLeaf: false,
        children: generateMonths(year).map(month => ({
          ...month,
          // children: generateDays(month.label),
        })),
      });
    }
    return years;
  };

  useEffect(() => {
    setOptions(generateYears());
  }, []);


  const [selectedState, setSelectedState] = useState("09")
  const [locationType, setLocationType] = useState('District')

  const {
    data: statesData,
    isFetching: isStatesDataFetching,
  } = useGetStateMasterQuery({}, { refetchOnMountOrArgChange: true })

  const {
    data: districtsData,
    isFetching: isDistrictsDataFetching,
  } = useGetDistrictMasterQuery({ selectedState }, { skip: selectedState === null })

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState({
    taskName: "ARD",
    stateName: "UTTAR PRADESH",
    stateCode: "09",
    districtName: "",
    districtCode: "",
    cropName: "",
    file: null,
    indice: {
      weather: [],
      MSI: [],
      SAR: []
    },
    years: []
  })

  const handleSubmit = () => {
    setIsSubmitLoading(true)
    const formData = new FormData()

    formData.append("task_name", selectedLocation.taskName)
    formData.append("state_name", selectedLocation.stateName)
    formData.append("district_id", selectedLocation.districtCode)
    formData.append("district_name", selectedLocation.districtName)
    formData.append("crop_name", selectedLocation.cropName)
    formData.append("file", selectedLocation.file)
    formData.append("indices", JSON.stringify(selectedLocation.indice))
    formData.append("years", JSON.stringify(selectedLocation.years))


    fetch("https://datapipeline-api.agrani.tech/create_task", {
      method: 'POST',
      body: formData
    }).then((resp) => {
      console.log(resp)
      setIsSubmitLoading(false)
      window.location.reload()
    })
  }

  useEffect(() => {
    console.log(selectedLocation)
  }, [selectedLocation])

  return (
    <Box className="w-full h-[450px] flex flex-col justify-between  gap-8">
      <Box className="w-full flex flex-col justify-center  gap-8">
        <Box className="w-full justify-between flex space-x-6">
          <Select
            placeholder="Select Location Type"
            className="w-1/3"
            onChange={(e) => { setLocationType(e.value) }}
            options={[
              {
                label: "District",
                value: "District"
              },
              {
                label: "GeoJson File",
                value: "GeoJson File"
              }
            ]}
          />
          {locationType === 'District' ? (
            <>
              <Select
                placeholder="Select State"
                isDisabled={true}
                isLoading={isStatesDataFetching}
                value={selectedLocation.stateName ? ({ label: selectedLocation.stateName, value: selectedLocation.stateCode }) : null}
                onChange={(e) => { setSelectedState(e.value); setSelectedLocation({ ...selectedLocation, stateName: e.label, stateCode: e.value }) }}
                className="w-1/3"
                options={
                  statesData && statesData.data?.map((state) => {
                    return {
                      label: state.state_name,
                      value: state.state_code
                    }
                  }).sort((a, b) => (a.label > b.label) ? 1 : -1)
                }
              />

              <Select
                placeholder="Select District"
                className="w-1/3"
                isDisabled={isDistrictsDataFetching}
                isLoading={isDistrictsDataFetching}
                value={selectedLocation.districtName ? ({ label: selectedLocation.districtName, value: selectedLocation.districtCode }) : null}
                onChange={(e) => { setSelectedLocation({ ...selectedLocation, districtName: e.label, districtCode: e.value }) }}
                options={
                  districtsData && districtsData.data?.map((district) => {
                    return {
                      label: district.district_name,
                      value: district.district_code
                    }
                  }).sort((a, b) => (a.label > b.label) ? 1 : -1)
                }
              />
            </>
          ) : null
          }

        </Box>
        {locationType === 'GeoJson File' ? <FileUpload setFiles={(files) => setSelectedLocation({ ...selectedLocation, file: files })} /> : null}
        {/* <Box className="w-full justify-between flex space-x-6">
          <Select
            isMulti
            placeholder="Select MSI"
            className="w-full"
            onChange={(e) => setSelectedLocation({...selectedLocation, indice: {...selectedLocation.indice, MSI: e.map((item) => item.value)}})}
            options={[{ label: 'NDVI', value: 'NDVI' }, { label: 'NDMI', value: 'NDMI' }, { label: 'NDRE', value: 'NDRE' }, { label: 'CIG', value: 'CIG' }, { label: 'CIRedEdge', value: 'CIRedEdge' }]}
          />
          <Select
            isMulti
            placeholder="Select SAR"
            className="w-full"
            onChange={(e) => setSelectedLocation({...selectedLocation, indice: {...selectedLocation.indice, SAR: e.map((item) => item.value)}})}
            options={[{ label: 'RVI', value: 'RVI' }]}
          />
        </Box> */}
        <Box className="w-full justify-between flex space-x-6">
          <Select
            isMulti
            placeholder="Select Weather"
            className="w-full"
            onChange={(e) => setSelectedLocation({...selectedLocation, indice: {...selectedLocation.indice, weather: e.map((item) => item.value)}})}
            options={[{ label: 'Min Temperature', value: 'Temperature_Air_2m_Min_24h' }, { label: 'Max Temperature', value: 'Temperature_Air_2m_Max_24h' }, { label: 'Precipitation', value: 'Precipitation_Flux' }, { label: 'Wind Speed', value: 'Wind_Speed_10m_Mean' }, { label: 'Relative Humidity', value: 'Relative_Humidity_2m_06h' }, { label: 'Solar Radiation', value: 'Solar_Radiation_Flux' }]}
          />
          <Cascader
            multiple
            placeholder="Select Years / Months"
            onChange={(event) => setSelectedLocation({ ...selectedLocation, years: event })}
            className='[&_.ant-select-selector]:!rounded [_.ant-select-dropdown]:!border !w-full h-10 text-left'
            dropdownMatchSelectWidth={true}
            options={options}
            changeOnSelect
          />
        </Box>
      </Box>

      <Box className="w-full flex justify-end">
        <Button className='!bg-orange-500  h-10' disableElevation variant="contained" onClick={handleSubmit}>
          Submit {isSubmitLoading ? <ReloadOutlined spin={isSubmitLoading} size={"small"} /> : null}
        </Button>
      </Box>
    </Box>
  )
}

export default ARDTask