import React from 'react';
import { Button, Result } from 'antd';
const TaskCreationMessage = ({ taskCreatedStatus, CreateOneMoreTask, handleTaskCreationDrawer }) => {

  if (taskCreatedStatus === 200 ) {
    return (
      <Result
        status="success"
        title="Successfully Created a Task"
        subTitle="Your task request has been successfully received. The results are currently under processing, you will receive a notification as soon as the task completed."
        extra={[
          <Button type="primary" key="console" className='bg-blue-500' onClick={() => handleTaskCreationDrawer(false)}>
            Go Home
          </Button>,
          <Button key="buy" onClick={() => CreateOneMoreTask(true)}>Create Another Task</Button>,
        ]}
      />
    )
  }

  return (
    <Result
      status="error"
      title="Failed To Create a Task"
      subTitle="Something Went Wrong. Please check the information you have provided and try again."
      extra={[
        <Button type="primary" key="console" className='bg-blue-500' onClick={() => handleTaskCreationDrawer(false)}>
          Go Home
        </Button>,
        <Button key="buy" onClick={() => CreateOneMoreTask(true)}>Create Another Task</Button>,
      ]}
    />
  )
}

export default TaskCreationMessage;