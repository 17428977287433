import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const { Dragger } = Upload;


const DragUpload = (props) => (
  <Dragger {...props}>
    <div className='p-3'>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Please Upload a geoJson or TIF file. Support for a single or bulk upload.
      </p>
    </div>
  </Dragger>
);
export default DragUpload;