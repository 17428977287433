import React, { useState } from 'react'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Table, Tag } from 'antd'
import { useNavigate } from 'react-router-dom';

const TaskTable = ({ tasks }) => {

  const TaskStatuses = {
    "Completed": {
      status: "success",
      icon: <CheckCircleOutlined />,
      color: "success"
    },
    "In Progress": {
      status: "processing",
      icon: <SyncOutlined spin />,
      color: "processing"
    },
    "Failed": {
      status: "error",
      icon: <CloseCircleOutlined />,
      color: "error"
    },
    InvalidInput: {
      status: "warning",
      icon: <ExclamationCircleOutlined />,
      color: "warning"
    }
  }

  const navigate = useNavigate()

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [pageOptions, setPagination] = useState({
    "current": 1,
    "pageSize": 10,
    "simple": true,
    "total": tasks.length,
    "pageSizeOptions": [
      10,
      50,
      100,
      500,
      1000
    ]
  })

  const handleChange = (pagination, filters, sorter) => {
    setPagination({ ...pageOptions, ...pagination })
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const columns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'task_id',
    //   key: 'task_id',
    //   filteredValue: filteredInfo.task_id || null,
    //   onFilter: (value, record) => record.task_id.includes(value),
    //   sorter: (a, b) => a.task_id.length - b.task_id.length,
    //   sortOrder: sortedInfo.columnKey === 'task_id' ? sortedInfo.order : null,
    //   ellipsis: true,
    // },
    {
      title: 'State Name',
      dataIndex: 'state',
      key: 'state',
      filteredValue: filteredInfo.state || null,
      onFilter: (value, record) => record.state.includes(value),
      sorter: (a, b) => a.state?.length - b.state?.length,
      sortOrder: sortedInfo.columnKey === 'state' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'District Name',
      dataIndex: 'district',
      key: 'district',
      filteredValue: filteredInfo.district || null,
      onFilter: (value, record) => record.district.includes(value),
      sorter: (a, b) => a.district?.length - b.district?.length,
      sortOrder: sortedInfo.columnKey === 'district' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'task_name',
      key: 'task_name',
      filteredValue: filteredInfo.task_name || null,
      onFilter: (value, record) => record.task_name.includes(value),
      sorter: (a, b) => a.task_name?.length - b.task_name?.length,
      sortOrder: sortedInfo.columnKey === 'task_name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Parameters',
      dataIndex: 'indices',
      key: 'indices',
      filteredValue: filteredInfo.indices || null,
      onFilter: (value, record) => record.indices.includes(value),
      sorter: (a, b) => a.indices?.length - b.indices?.length,
      sortOrder: sortedInfo.columnKey === 'indices' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Created At',
      dataIndex: 'created',
      key: 'created',
      filteredValue: filteredInfo.created || null,
      onFilter: (value, record) => record.created.includes(value),
      sorter: (a, b) => a.created?.length - b.created?.length,
      sortOrder: sortedInfo.columnKey === 'created' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Status',
      key: 'status',
      render: ({ status }) => <Tag icon={TaskStatuses[status]?.icon || ""} color={TaskStatuses[status]?.color || "default"}>{status}</Tag>,
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (operation) => (
        <div className='w-full flex justify-around'>
          <Button className='' onClick={() => navigate(`/tasks/${operation.task_id}?type=${operation.task_name}`)}>view</Button>
          {/* <Button className='' onClick={() => navigate(`/tasks/${operation.task_id}?type=${operation.task_name}`)}>Download</Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      className='!h-screen overflow-auto'
      size="middle"
      columns={columns}
      dataSource={tasks}
      onChange={handleChange}
      bordered
      // footer={() => <div></div>}
      scroll={{
        y: 500,
      }}
      rowSelection
      loading={false}
      rowKey="task_id"

      pagination={{
        simple: true,
        current: pageOptions.current,
        pageSize: pageOptions.pageSize,
        total: pageOptions.total,
        pageSizeOptions: pageOptions.pageSizeOptions
      }}
    />
  )
}

export default TaskTable