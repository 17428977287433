import { apiSlice } from "app/api/apiSlice"

export const TasksApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getTaskSList: builder.query({
      query: () => "/tasklist"
    }),
    getTaskById: builder.query({
      query: (taskId) => `/task_details?taskId=${taskId}`
    }),
    createTask: builder.mutation({
      query: (payload) => ({url:"/create_task", method: 'POST', body: payload})
  }),
  })
})

export const {
useGetTaskSListQuery,
useGetTaskByIdQuery,
useCreateTaskMutation
} = TasksApiSlice 