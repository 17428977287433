import { useEffect, useState } from 'react'
import { Button, Cascader, Drawer, Space, Tabs } from 'antd'
import CreateNewTask from 'components/Task/TaskCreation'
import TaskTable from 'components/Task/TaskList/TaskTable'
import { useGetTaskSListQuery } from './Tasks/tasksApiSlice'
import AcreageTask from 'components/Task/TaskCreation/AcreageTask'
import SpectralProfiling from 'components/Task/TaskCreation/SpectralProfilingTask'
import Select from 'react-select'
import { Box, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ReloadOutlined } from '@ant-design/icons'
import { LocalDiningRounded } from '@mui/icons-material'
import ARDTask from 'components/Task/TaskCreation/ARDTask'

const Dashboard = () => {

  const {
    data: tasksData,
    isFetching: isTasksDataLoading,
    isSuccess: isTasksDataSuccess,
    isError: isTasksDataError,
    refetch: taskListRefetch,
    error: taskDistrictDataError
  } = useGetTaskSListQuery(undefined, { refetchOnMountOrArgChange: true })

  const [drawerState, setDrawerState] = useState(false)
  const [tasksList, setTasksList] = useState([])

  useEffect(() => {
    if (tasksData && tasksData.data && tasksData.data.length > 0) {
      setTasksList(tasksData && tasksData.data)
    }
  }, [tasksData])

  const handleTaskCreationDrawer = (isOpen) => {
    setDrawerState(isOpen)
  }

  const [taskType, setTaskType] = useState()

  

  return (
    <div className='border w-full h-full bg-white border-gray-300 shadow p-5'>
      <Drawer
        title="Create a task"
        width={720}
        onClose={() => handleTaskCreationDrawer(false)}
        open={drawerState}
      >

        <Box className="flex flex-col gap-10">

          <Tabs

            items={[
              // {
              //   className: 'p-4',
              //   key: "Spectral Profiling",
              //   label: "Spectral Profiling",
              //   children: (
              //     <SpectralProfiling />
              //   )
              // },
              // {
              //   className: 'p-4',
              //   key: "Acreage",
              //   label: "Acreage",
              //   children: (
              //     <AcreageTask />
              //   )
              // },
              {
                className: 'p-4',
                key: "ARD",
                label: "Weather Data",
                children: (
                  <ARDTask />
                )
              }
            ]}
          />

          {/* {
            taskType === 'ARD' ? (
              <CreateNewTask handleTaskCreationDrawer={handleTaskCreationDrawer} />
            ) : null
          }
          {
            taskType === 'Acreage' ? (
              <div>
                <AcreageTask />
              </div>
            ) : null
          }
          {
            taskType === 'Spectral Profiling' ? (
              <div>
                <SpectralProfiling />
              </div>
            ) : null
          } */}
        </Box>
      </Drawer>
      <Space className='mb-6 flex justify-end'>

        <Space>
          <Button
            // className='rounded-none border-gray-300 shadow'
            className='!border-orange-500 !border !flex !justify-center !items-center !outline-none !text-orange-500 hover:!text-orange text-center !rounded-sm'
            onClick={() => taskListRefetch()}
          >
            {isTasksDataLoading ? "Loading ..." : "Refresh"} <ReloadOutlined spin={isTasksDataLoading} size={"small"} />
          </Button>
          <Button
            // className='rounded-none border-gray-300 shadow'
            className='bg-orange-500 border-none outline-none text-white hover:!text-white text-center !rounded-sm'
            onClick={() => handleTaskCreationDrawer(!drawerState)}>Create Task
          </Button>
        </Space>
      </Space>
      <TaskTable tasks={tasksList} />
    </div>
  )
}

export default Dashboard